	<!--反馈列表-->
<template>
  <div>
  <el-form ref="searchForm"   label-width="240" :inline="true">		
	  <el-form-item label="综合查询:" prop="name"><el-input v-model="tableDataName" placeholder="请输入名称" style="width:240px" clearable></el-input></el-form-item>
    <el-form-item><el-button type="primary" @click="handleClickQuery()">搜索</el-button></el-form-item>
	<el-form-item><el-button type="primary" @click="addition()">新增</el-button></el-form-item>
	<!-- <el-form-item><el-button type="primary" @click="addMaterial()">新增</el-button></el-form-item> -->
  </el-form>
  <el-table :data="dataList" border  ref="multipleTable">
    <el-table-column label="序号"  width="100px">
    	<template v-slot="scope">
    		{{ scope.$index+1 }}
    	</template>
    </el-table-column>
	<el-table-column prop="list_value" label="提示内容" ></el-table-column>
	<!-- <el-table-column prop="list_desc" label="补充说明" ></el-table-column> -->
    <el-table-column fixed="right" label="操作" width="300px" >
      <template v-slot="scope">
       <el-button  icon="el-icon-edit"    @click="Modify(scope.row)" type="success" size="small">编辑</el-button>
       <el-button  icon="el-icon-delete"   @click="Deleteinfo(scope.row)" type="danger" size="small">删除</el-button>
      </template>
    </el-table-column>
  </el-table>
    <el-config-provider :locale="locale">
      <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-size="pagesize"
            layout="total,sizes,prev,pager,next,jmper"
            :total="totalCount">
      </el-pagination>
    </el-config-provider>

    <rms_report_info @closeReportInfo="closeReportInfo"  v-if="reportInfoDialogVisible"></rms_report_info>
  </div>
</template>
<script>
import rms_report_info from './rms_dictionary_info.vue'
//引入vue方法
import { ElConfigProvider } from 'element-plus';
//中文包
import zhCn from "element-plus/lib/locale/lang/zh-cn";
export default {

  name: 'ZhProvider',

  setup(){
    let locale = zhCn
    return{
      locale
    }
  },
	data() {
	  let self=this;
	  return {
		// ids:[],
	    dataList:[],  //表格数据集
	    reportInfoDialogVisible: false,
	    tableDataName:'',   //综合查询条件参数
	    optionsype:'',     //所属项目选项
	    project:'',       //选择的所属项目
		content:'',
		status:'',
		memo:'',
		create_time:'',
		update_time:'',
	    totalCount:0,          //总页数
	    pagesize:10,        //每页数量
	    currentPage:1,    //初始页
		id:0,
		title:""
	  }
	},

  /*启动后触发该方法*/
  created() {
    this.handleClickQuery();
  },
  methods: {
    handleClickQuery:function(data){
      this.dataList = [];
      var req = {};
      req.cmd = "searchDictionaryTableList";
      req.start=(this.currentPage-1) * this.pagesize;
      req.count=this.pagesize;
	  if(this.tableDataName){
		  req.list_value = this.tableDataName;
	  }
      this.$sknet.postJson(this.$skconf("met.surl"), req, function(res) {
        console.log(res);
        this.dataList=res.datas.items;
        this.totalCount=res.datas.total;
      }, null, this);
    },
    Modify: function(item) {
	  this.id = item.id;
	  this.name = item.list_value;
	  this.mark = item.list_desc;
      this.reportInfoDialogVisible = true;
    },
    closeReportInfo:function(){
      this.reportInfoDialogVisible = false;
      this.handleClickQuery();
    },
    //条件查询的方法
    queryMaterial:function(){
        this.dataList = [];
        var req = {};
		req.synthesize_params=this.tableDataName;
        req.cmd = "searchReportList";
        req.start=(this.currentPage-1) * this.pagesize;
        req.count=this.pagesize;
        this.$sknet.postJson(this.$skconf("met.surl"), req, function(res) {
          console.log(res);
          this.dataList=res.datas.items;
          this.totalCount=res.datas.totalCount;
        }, null, this);
    },
	addition:function(item){
		this.id = 0;
		this.title = "新增报修内容";
	  this.reportInfoDialogVisible = true;
	},
	Deleteinfo:function(item){
	  this.$confirm('是否确认删除?', '提示', {
	    confirmButtonText: '确定',
	    cancelButtonText: '取消',
	    type: 'warning'
	  }).then(() => {
	    this.deleteReport(item.id);
	  });
	},	
	deleteReport:function(id){
		var self = this;
		var req = {};
		req.id = id;
		req.cmd = "delDictionaryTableList"
		this.$sknet.postJson(this.$skconf("met.surl"), req, function(res){
		  self.$message("删除成功");
		  self.handleClickQuery();
		}, function(data) {
		}, self);
	},
    /*分页代码*/
    handleSizeChange: function (size) {
      this.pagesize = size;
      console.log(this.pagesize)  //每页下拉显示数据
    },
    handleCurrentChange: function(currentPage){
      this.currentPage = currentPage;
      this.handleClickQuery();
      console.log(this.currentPage)  //点击第几页

    },
	//根据返回值判断
	formatter:function(row,colum){
	  const status={
	    0:'未处理',
	    1:'已处理',
	  }
	  return  status[row.status];
	},
  },


  components: {
	  rms_report_info,
    [ElConfigProvider.name]: ElConfigProvider,
  },


}


</script>