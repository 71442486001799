<template>
	<el-dialog :title="this.title" v-model="materialEditDialogVisible" width="30%" center :close-on-click-modal = "false" @close='closeMaterialEditinfo'>
		<el-form label-width="100px" class="ruleForm">	
			<el-form-item label="常用语内容"  >
			  <el-input v-model="name"></el-input>
			</el-form-item>
			<!-- <el-form-item label="补充说明"  >
			  <el-input v-model="mark"></el-input>
			</el-form-item> -->
		</el-form>
		<div class="ucForm-group-ad">
		  <div class="ucForm-group ucBtn-bg" style="text-align: center;">
		    <button class="ucBtn ucBtn-ok ucBtn-blue" @click="commit()"><i class="iImg"></i>确定</button>
		    <button class="ucBtn ucBtn-refresh ucBtn-gray" @click="closeMaterialEditinfo()"><i
		        class="iImg"></i>返回</button>
		  </div>
		</div>
		<div class="clear"></div>
	</el-dialog>
</template>
<script>
	
	export default {
		name: 'ZhProvider',
		
		data: function() {
			
			return {
				name:this.$parent.name||"",
				father_id:this.$parent.father_id,
				mark:this.$parent.mark||"",
				id:this.$parent.id,
				fids:[],
				title:this.$parent.title,
			}
		},
		created() {
			this.materialEditDialogVisible = true;
		},
		methods: {
			commit: function() {
				var self = this;
				var req = {};
				req.cmd = "insertDictionaryTableList"
				if(this.id >0){
					req.cmd = "updateDictionaryTableList"
					req.id = this.id
				}
				if(this.name==null||this.name==''){
					self.$message("警示内容不能为空");
					return;
				}else{
					req.list_value = this.name;
				}
				req.list_desc = this.mark;
					
				this.$sknet.postJson(this.$skconf("met.surl"), req, function(res) {
					self.$message(res.reason);
					self.closeMaterialEditinfo();
				}, null, self);
			
			},
			closeMaterialEditinfo: function() {
				this.materialEditDialogVisible = false;
				this.clearinfo();
				this.$emit('closeReportInfo'); //通知父组件改变。
			},
			clearinfo:function(){
				this.$parent.id = 0;
				this.$parent.name ="";
				this.$parent.mark = "";
			},
		},
		mounted() {
			// this.$nextTick(function(){
			// 	this.drawcategory('main');
			// })
		},
		components: {
			
		}
	}
</script>

<style scoped>

</style>
